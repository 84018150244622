import React from "react"

import Section from "../../Elements/Section"
import formFields from "../utils/patientEnrollmentWhereDidYouHearAboutUs.json"
import { generateFormField } from "../../Elements/Form/services/form"

const WhereDidYouHearAboutUs = ({ values }) => {
  const whereDidYouHearAboutUsFields = formFields.map((formField) => {
    if (!formField?.referenceAnswer)
      return generateFormField({ formField, values, formFields })
  })

  return (
    <Section
      title="Where did you hear about us?"
      id="where-did-you-hear-about-us"
    >
      {whereDidYouHearAboutUsFields}
    </Section>
  )
}

export default WhereDidYouHearAboutUs
