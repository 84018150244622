import React from "react"

import Section from "../../Elements/Section"
import FormInput from "../../Elements/Form/FormInput"

const SecondaryContactPerson = () => (
  <Section title="Secondary Contact Person" id="secondary-contact-person">
    <FormInput
      name="secondaryContactFullName"
      label="Full Name"
      placeholder="Maria Dela Cruz Jr."
      isRequired
    />
    <FormInput
      name="secondaryContactMobileNumber"
      label="Mobile Number"
      placeholder="09991234567"
      type="tel"
      pattern="[0,9]{2}[0-9]{9}"
      maxLength={11}
      isRequired
      isNumeric
    />
  </Section>
)

export default SecondaryContactPerson
